import React, { useState } from 'react';
import './Footer.css';

function Footer() {
    // État local pour stocker l'adresse e-mail
    const [email, setEmail] = useState('');
    // État local pour stocker l'adresse IP de l'utilisateur
    // const [userIP, setUserIP] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [emailAdded, setEmailAdded] = useState(false);
    const [emailAlreadyAdded, setEmailAlreadyAdded] = useState(false);
    // Fonction pour mettre à jour l'adresse e-mail à chaque changement dans l'input
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    // Fonction pour envoyer l'adresse e-mail et l'adresse IP au backend lorsque le formulaire est soumis
    const handleSubmit = async (event) => {
        event.preventDefault();
        const emailReg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i);
        var isValidEmail = emailReg.test(email);
        setEmailValid(isValidEmail);
        if (!isValidEmail) {
            return;
        }

        try {
            // Récupérer l'adresse IP de l'utilisateur uniquement au moment de la soumission du formulaire
            const responseIP = await fetch('https://api.ipify.org?format=json');
            const dataIP = await responseIP.json();
            const userIP = dataIP.ip;

            // Envoyer l'adresse e-mail et l'adresse IP au backend
            const response = await fetch('/api/submit-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, userIP }), // Envoyer l'adresse e-mail et l'adresse IP
            });

            if (response.ok) {
                setEmailAdded(true);
                setEmail('');
            } else if (response.status === 409) {
                setEmailAlreadyAdded(true);
            } else {
                console.error('Erreur lors de l\'ajout de l\'adresse e-mail à la base de données.');
            }
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire :', error);
        }
    };

    const handleFocus = () => {
        setEmailAdded(false);
        setEmailAlreadyAdded(false);
        setEmailValid(true); // Réinitialiser l'état de la validité de l'email lorsqu'on interagit avec le formulaire
    };

    const handleKeyDown = () => {
        setEmailValid(true); // Réinitialiser l'état de la validité de l'email lorsqu'on interagit avec le formulaire
        setEmailAlreadyAdded(false);
    };

    return (
        <footer className="Footer">
            <div className="FirstContainer">
                <div className='Newsletter'>
                    <h3>Inscrivez-vous à notre Newsletter: </h3>
                    <form className='FooterForm' onSubmit={handleSubmit} noValidate>
                        <input
                            type="email"
                            placeholder="Entrez votre adresse e-mail"
                            value={email}
                            onChange={handleEmailChange}
                            onFocus={handleFocus}
                            onKeyDown={handleKeyDown}
                        />
                        {!emailValid && <p className="errorMessage">Veuillez saisir une adresse e-mail valide.</p>}
                        {emailAdded && <p className="successMessage">Merci pour votre inscription !</p>}
                        {emailAlreadyAdded && <p className="successMessage">Cette adresse e-mail est déjà inscrite.</p>}
                        <button className="MailSubmit" type="submit">
                            <img src="/white-arrow.png" alt="White Arrow" />
                        </button>
                    </form>
                </div>
                <div className="ContactInfo">
                    <p>
                        Si vous craignez un geste suicidaire ou de violence, appelez le Samu ou le 3114. Des psychologues et des infirmiers sont à votre écoute 24h/24 et 7j/7.
                    </p>
                </div>
            </div>
            <div className="Precog">
                <p>Precog© 2024 - Tous droits réservés</p>
            </div>
        </footer>
    );
}

export default Footer;
