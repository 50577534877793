import Question from './Question';

const questionsData = [
  { id: 1, questionText: "Investissez-vous beaucoup de temps et d'énergie dans votre travail ?" },
  { id: 2, questionText: "Éprouvez-vous le besoin que les tâches soient parfaitement réalisées ?" },
  { id: 3, questionText: "Êtes-vous sollicité par vos collègues ou supérieurs pour effectuer des tâches supplémentaires ?", isFrequency: true},
  { id: 4, questionText: "Ressentez-vous de l'anxiété ou des préoccupations en pensant à votre travail, même en dehors des heures de travail ?", isFrequency: true },
  { id: 5, questionText: "Vous sentez-vous dépassé par la quantité de travail que vous avez à accomplir ?"},
  { id: 6, questionText: "Avez-vous du mal à déconnecter du travail, même en dehors des heures de bureau ?" },
  { id: 7, questionText: "Éprouvez-vous des difficultés à dormir en raison du stress lié au travail ?", isFrequency: true },
  { id: 8, questionText: "Vous sentez-vous isolé ou seul au travail ?"},
  { id: 9, questionText: "Avez-vous perdu l'intérêt pour les activités sociales en dehors du travail ?" },
  { id: 10, questionText: "Avez-vous remarqué des changements dans votre humeur, comme de l'irritabilité ou de l'impatience ?", isFrequency: true },
  { id: 11, questionText: "Ressentez-vous des tensions physiques telles que des douleurs musculaires ou des maux de tête ?", isFrequency: true },
  { id: 12, questionText: "Avez-vous constaté des changements dans votre appétit ou votre poids ?" },
  { id: 13, questionText: "Éprouvez-vous des difficultés à vous concentrer ou à prendre des décisions au travail ?", isFrequency: true },
  { id: 14, questionText: "Avez-vous remarqué une diminution de votre motivation ou de votre satisfaction au travail ?" },
  { id: 15, questionText: "Vous sentez-vous submergé par les attentes professionnelles ?" },
  { id: 16, questionText: "Avez-vous recours à des substances comme l'alcool, le tabac ou les médicaments pour faire face au stress ?", isFrequency: true },
  { id: 17, questionText: "Vous sentez-vous coupable ou incompétent en raison de votre performance au travail ?" },
  { id: 18, questionText: "Avez-vous des symptômes physiques tels que des palpitations, des vertiges ou des nausées liées au stress ?", isFrequency: true },
  { id: 19, questionText: "Avez-vous des pensées négatives sur votre travail ou votre avenir professionnel ?", isFrequency: true },
  { id: 20, questionText: "Avez-vous déjà ressenti une forte envie de tout abandonner et de vous éloigner de votre travail ?" }
];


const questions = questionsData.map(({ id, questionText,isFrequency }) => new Question(id, questionText, isFrequency));
export default questions;