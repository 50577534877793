import Choice from "./Choice";

class Question {
    constructor(id, questionText, isFrequency=false) {
      this.id = id;
      this.questionText = questionText;
      this.choices = [
        new Choice(0, "Non, pas du tout",'#94657E'),
        new Choice(1,null,'#94657E'),
        new Choice(2,null,'#c5c5c5'),
        new Choice(3,null,'#4C9070'),
        new Choice(4, "Oui, tout à fait",'#4C9070')
      ];
      if (isFrequency) {
        this.choices = [
          new Choice(0, "jamais (ou presque)",'#94657E'),
          new Choice(1,"rarement",'#94657E'),
          new Choice(2,"parfois",'#c5c5c5'),
          new Choice(3,"souvent",'#4C9070'),
          new Choice(4, "très souvent",'#4C9070')
        ];
      }

    }

    setChoices(choices) {
      this.choices = choices;
    }
  
    // Autres méthodes de validation, de manipulation des choix, etc.
  }
  
  export default Question;
  