import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './App.css';
import questions from './components/questionsData';
import HtmlQuestion from './components/HtmlQuestion';
import Footer from './components/Footer';
import ResultsPopup from './components/ResultsPopup';

function calculateTestDuration(startDate) {
  const endDate = new Date(); // Date actuelle
  const durationInMilliseconds = endDate.getTime() - startDate.getTime(); // Durée en millisecondes
  const durationInSeconds = Math.round(durationInMilliseconds / 1000); // Conversion en secondes et arrondi
  return durationInSeconds;
}

function calculateStressScore(answeredQuestions) {
  return Math.round((answeredQuestions.reduce((total, item) => total + item.choiceId, 0) / (questions.length * 4)) * 100).toFixed(0);
}

const halfwayIndex = Math.floor(questions.length / 2);

function App() {
  const [startDate, setStartDate] = useState(new Date()); // État local pour stocker la date de début
  // const updateStartDate = () => {
  //   setStartDate(new Date()); 
  // };
  const [answeredQuestions, setAnsweredQuestions] = useState([]); // État pour enregistrer les questions auxquelles l'utilisateur a répondu
  const [resultsButtonResponseText, setResultsButtonResponseText] = useState(""); // État pour stocker le texte du bouton de réponse
  const [showPopup, setShowPopup] = useState(false);

  const [userIP, setIP] = useState();
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIP(data.ip))
      .catch(error => console.error('Erreur lors de la récupération de l\'adresse IP :', error))
  }, []);

  // Fonction pour mettre à jour les questions répondues
  const updateAnsweredQuestions = useCallback((questionId, choiceId) => {
    // Vérifie si la question est déjà répondue
    const questionIndex = answeredQuestions.findIndex(item => item.questionId === questionId);
    if (questionIndex !== -1) {
      // Met à jour la réponse de la question existante
      const updatedAnsweredQuestions = [...answeredQuestions];
      updatedAnsweredQuestions[questionIndex].choiceId = choiceId;
      setAnsweredQuestions(updatedAnsweredQuestions);
    } else {
      // Ajoute une nouvelle réponse
      setAnsweredQuestions([...answeredQuestions, { questionId, choiceId }]);
    }
  }, [answeredQuestions]);

  // Gestionnaire d'événements pour le clic sur le bouton de voir les résultats
  const handleResultsButtonClick = useCallback(
    async () => {
      if (answeredQuestions.length !== questions.length) {
        setResultsButtonResponseText("Veuillez d'abord répondre à toutes les questions");    
      } else {
        setResultsButtonResponseText("");
        try {
          const response = await fetch('/api/submit-results', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_ip_address: userIP, 
              stress_score: calculateStressScore(answeredQuestions), // Ajoutez le score de stress calculé
              test_date: new Date().toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }), // Ajoutez la date et l'heure actuelle
              individual_responses: JSON.stringify(answeredQuestions), // Ajoutez les réponses individuelles
              test_duration: calculateTestDuration(startDate), // Ajoutez la durée du test calculée
              test_version: '1.0' // Ajoutez la version du test
            }),
          });
          if (response.ok) {
            console.log('Résultats du test envoyés avec succès.');
          } else {
            console.error('Erreur lors de l\'envoi des résultats du test au serveur.');
          }
        } catch (error) {
          console.error('Erreur lors de l\'envoi des résultats du test au serveur :', error);
        }
        setShowPopup(true);
      }
    }
  , [answeredQuestions, startDate, userIP]);

  // Détermine la largeur de la barre de progression en fonction du pourcentage de questions répondues
  const progressBarWidth = useMemo(() => {
    const percentage = (answeredQuestions.length / questions.length) * 100;
    return `${Math.round(percentage)}%`;
  }, [answeredQuestions.length]);
  
  return (
    <div className="App">
      <header className="Header">
        <h1>Precog</h1>
        <h2>Ce qui se mesure s'améliore</h2>
      </header>
      <main className="Main">
        <div className="Disclaimer">
          <h2>Attention !</h2>
          <p>
            <ul>
              <li>Cet outil a été créé dans le seul but de vous aider à prendre conscience de votre état de stress.</li>
              <li>Il ne constitue un avis médical en aucun cas.</li>
              <li>Quel qu'en soit le résultat, n'hésitez pas à vous rapprocher de votre service de santé au travail ou de votre médecin généraliste.</li>
            </ul>
          </p>
        </div>
        {questions.map(question => (
          <HtmlQuestion
            halfwayBoolean={question.id===halfwayIndex ? true : false}
            questionText={question.questionText}
            choices={question.choices}
            updateAnsweredQuestions={(choiceId) => updateAnsweredQuestions(question.id, choiceId)} // Passer la fonction pour mettre à jour les questions répondues
            questionAnswer={answeredQuestions.find(item => item.questionId === question.id)?.choiceId} // Passer la réponse de la question si elle a déjà été répondue
          />
        ))}

        <button className="ResultsButton" onClick={handleResultsButtonClick}>
          Voir les résultats
        </button>
        {resultsButtonResponseText && (
          <div className="PleaseAnswer"> <p>{resultsButtonResponseText}</p> </div>
        )}
        
        <ResultsPopup
          answeredQuestions={answeredQuestions}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          questionsLength={questions.length}
        />
        <div className="ProgressBar" style={{ width: progressBarWidth }}></div>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
