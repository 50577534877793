import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './ResultsPopup.css';

function ResultsPopup({ answeredQuestions, showPopup, setShowPopup, questionsLength }) {
  const [rotateAngle, setRotateAngle] = useState(135);

  useEffect(() => {
    if (showPopup) {
      // Mettre à jour l'angle de rotation après un court délai
      setTimeout(() => {
        const finalAngle = ((answeredQuestions.reduce((total, item) => total + item.choiceId, 0) / (questionsLength * 4)) * 180 + 135).toFixed(2);
        setRotateAngle(finalAngle);
      }, 100);
    }
  }, [showPopup]);

  const stressScore = Math.round((answeredQuestions.reduce((total, item) => total + item.choiceId, 0) / (questionsLength * 4)) * 100).toFixed(0);
  function Comments(stressScore) {
      if (stressScore < 25) {
        return "Vous êtes très détendu. Continuez comme ça !"
      }
      else if (stressScore < 50) {
        return "Vous êtes légèrement stressé. Faites attention à vous."
      }
      else if (stressScore < 75) {
        return "Vous êtes très stressé. Réflechissez à la possibilité de consulter un professionnel."
      }
      else {
        return "Vous êtes extrêmement stressé. Il serait judicieux de consulter un professionnel."
      }
    }
      
      
    return (
        <Popup
          open={showPopup}
          closeOnDocumentClick={false}
          onClose={() => setShowPopup(false)}
          modal
        >
          {close => (
            <div className="modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="popup-content">
                <h2>Votre score de stress est {stressScore}</h2>
                <div className="gauge-container">
                  <img src="/gauge.svg" alt="Counter" className="gauge" />
                    <img
                      src="/needle.png"
                      alt="Needle"
                      className="needle"
                      style={{ transform: `rotate(${rotateAngle}deg)` }}
                    />
                </div>
                <p className='comments'> {Comments(stressScore)}</p>
                <p className='Hint'>
                  <img src="bulb-icon.png" alt="Icône d'ampoule" className="BulbIcon" />
                  <strong>Le saviez-vous ?</strong> L'État Français a mis en place le dispositif <a href="https://www.info.gouv.fr/actualite/monparcourspsy-un-dispositif-pour-faciliter-lacces-a-un-accompagnement-psychologique" target="_blank" rel="noopener noreferrer">Mon Soutien Psy</a> pour faciliter l'accès aux soins psychologiques.
                </p>

              </div>
            </div>
          )}
        </Popup>
    );
}

export default ResultsPopup;
