import React from 'react';
import './Question.css';

function HtmlQuestion(props) {
  const { halfwayBoolean, questionText, choices, updateAnsweredQuestions, questionAnswer } = props;
  
  const handleChoiceClick = (choiceValue) => {
    updateAnsweredQuestions(choiceValue); // Appeler la fonction de rappel pour mettre à jour les questions répondues
  };

  return (
    <div>
      <div className="Question">
        <h3>{questionText}</h3>
        <div className="Choices">
          {choices.map((choice, index) => (
            <button
              key={index}
              className='ChoiceButton'
              value={choice.value}
              onClick={() => {
                handleChoiceClick(choice.value);
              }}
              onMouseOver={(event) => {
                event.target.style.backgroundColor = choice.color;
              }}
              onMouseOut={(event) => {
                event.target.style.backgroundColor = questionAnswer === choice.value ? choice.color : '#fff';
              }}
              style={{ borderColor: choice.color, backgroundColor: questionAnswer === choice.value ? choice.color : '#fff', width: (index === 0 || index === 4) ? 40 : 30, height: (index === 0 || index === 4) ? 40 : 30 }}
            >
              {choice.text}
            </button>
          ))}
        </div>
        <div className={choices[1].legend === null ? "Legends" : "FullLegends"}>
          {choices.map((choice, index) => (
            <div key={index} className="LegendContainer">
              {choice.legend && (
                <div className="Legend" style={{ color: choice.color }}>
                  {choice.legend}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {halfwayBoolean && <div className="MiddleSentence"><h2>Soufflez... Vous en êtes à la moitié !</h2></div>}
    </div>
  );
}
  

export default HtmlQuestion;
